import './university-style.scss'

import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUKAccess } from '@/modules/useUKAccess'

import {
  AdsSection,
  CardGroupHeader,
  CardList,
  FilterMobile,
  Filters,
  Seo,
  Title,
} from '../../components'

const UniversityView = ({
  articles,
  trendingArticles,
  handleFilter,
  selectedCategories,
}) => {
  const { t, i18n } = useTranslation()
  const isUKIp = useUKAccess()
  let { filteredArticles, filteredTrendingArticles } = useMemo(() => {
    let data = articles
    let trendingData = trendingArticles
    if (isUKIp) {
      data = _.filter(articles, (item) => {
        return _.every(
          item.node.categories.nodes,
          (category) => category.slug !== 'derivatives-university-en'
        )
      })
      trendingData = _.filter(trendingArticles, (item) => {
        return _.every(
          item.node.categories.nodes,
          (category) => category.slug !== 'derivatives-university-en'
        )
      })
    }
    return { filteredArticles: data, filteredTrendingArticles: trendingData }
  }, [isUKIp, articles, trendingArticles])

  return (
    <div>
      <Seo
        title={t('university.meta.title')}
        description={t('university.meta.description')}
        link="/university"
        lang={i18n.language}
      />
      <FilterMobile
        type="university"
        title="topics"
        titleTwo="levels"
        typeTwo="university-levels"
        onClick={handleFilter}
        selectedCategories={selectedCategories}
      />
      <div className="university-container has-mobile-filter">
        <CardGroupHeader
          title="university"
          data={filteredTrendingArticles}
          titleUrl="/university"
        />
        <div id="university-box" className="university-box">
          <Title title={t('card-group.university')} />
          <Filters
            title="topics"
            type="university"
            titleTwo="levels"
            typeTwo="university-levels"
            onClick={handleFilter}
            selectedCategories={selectedCategories}
          />
          <CardList data={filteredArticles} />
        </div>
        <AdsSection />
      </div>
    </div>
  )
}

export default UniversityView
