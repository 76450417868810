import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useTemplateController from '../category-page-template/template-controller'
import UniversityView from './university-view'

const UniversityController = (props) => {
  const { i18n } = useTranslation()

  const lang = i18n.language

  let articles = useMemo(() => {
    return props.pageContext.articles.filter(
      (article) =>
        !article.node.acf.hideInLangs ||
        article.node.acf.hideInLangs.indexOf(lang) === -1
    )
  }, [props.pageContext.articles, lang])

  const viewProps = useTemplateController({
    data: { articles: { edges: articles } },
    location: props.location,
    slug: 'university',
    lang,
  })

  return <UniversityView {...viewProps} />
}

export default UniversityController
